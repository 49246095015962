import TitBlit from '@/assets/img/emergency-relief/covid19_tit_blit@2x.png';
import HeaderBg from '@/assets/img/emergency-relief/header_tit_bg@2x.png';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const EmergencyHeader = styled(SectionHeader)`
  h2 {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 581px;
      height: 544px;
      position: absolute;
      left: -230px;
      top: -117px;
      background: url(${HeaderBg});
      background-size: cover;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 50.09vw;
        height: 46.9vw;
        left: -19.83vw;
        top: -10.09vw;
      }
    }
  }
  p {
    margin-top: 32px;
  }
`;

export const WhyFlex = styled.div`
  display: flex;
  margin: 0 -16px;
  align-items: flex-end;
  padding-bottom: 96px;

  .col {
    width: 50%;
    padding: 0 16px;

    h3 {
      position: relative;
      padding-top: 64px;

      &::before {
        content: '';
        display: block;
        width: 164px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        background: url(${TitBlit}) center no-repeat;
        background-size: cover;
        z-index: -1;
      }

      ${breakpoint(`tablet`)} {
        padding-top: 35px;
      }
    }

    p {
      margin-top: 32px;
    }

    &.col-right {
      text-align: right;
    }
  }

  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;

    .col {
      width: 100%;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      &.col-right {
        text-align: center;
      }
    }
  }
`;

export const SectionWedo = styled(Section)`
  padding-bottom: 0 !important;

  .col3 {
    .process-ol {
      & > li {
        width: 33.33%;
      }
    }
  }

  .process-ol {
    justify-content: space-around;

    & > li {
      width: 25%;
      padding: 0 10px;
      margin: 60px 0 !important;

      dt {
        padding: 0 !important;
      }
      dd {
        max-width: 210px;
        margin: 0 auto;
        margin-top: 24px;
        color: #2d2926;

        .dec-ul {
          strong {
            color: #1cabe2;
            font-weight: 400;
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      & {
        margin: 0 -20px;

        & > li {
          width: 33.33% !important;
          margin: 32px 0 !important;
        }

        .img-wrap {
          width: 80px;
        }

        .dec-ul {
          br:not(.m-show) {
            display: none;
          }
        }
      }
    }

    ${breakpoint(`mobile`)} {
      & > li {
        width: 50% !important;
      }
    }
  }

  .accordion-opener {
    padding: 24px;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    position: relative;

    ${breakpoint(`tablet`)} {
      font-size: 16px;
      padding: 20px 0;
    }
  }

  .accordion-body {
    padding: 0 24px;
  }
`;
